import React from "react"
// import { graphql } from 'gatsby';

const Header = ({ logo, name }) => (
  <header className="header--absolute">
    <div className="container container--lg margin-top--md margin-bottom--md">
      <div className="flex flex--center flex--space-between">
        <div className="flex flex--center-y">
          {/* <img src="{data.post.logo} === {data.post.logo}" class="logo--sm" alt="logo jointacademy" /> */}
          {logo && <img className="logo--sm" src={logo} alt={name} />}
          {/* <span>{data.post.title}</span> */}
          {/* <h1 className="post-title">{data.post.title}</h1> */}
        </div>
      </div>
    </div>
  </header>
)

export default Header

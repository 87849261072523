import React from "react"

const allContent = {
  en: {
    title: "Get started today",
    checklists: [
      {
        text: "Download the app",
      },
      {
        text: "Check your coverage",
      },
      {
        text: "Begin your treatment",
      },
    ],
    button: "get started",
  },
  sv: {
    title: "Kom igång idag",
    checklists: [
      {
        text: "Ladda ner appen",
      },
      {
        text: "Logga in säkert med BankID",
      },
      {
        text: "Påbörja ditt personliga program",
      },
    ],
    button: "LADDA NER",
  },
}

const App = ({ appTitle, bp, bp2, bp3, button, lang, Phone }) => {
  const language = typeof lang === "undefined" ? "sv" : lang
  const content = allContent[language]
  return (
    <section data-theme="ice-blue" data-section="app">
      <div className="container container--md margin-top--lg margin-bottom--lg">
        <div className="grid grid--gap-lg flex--center-y">
          <div className="col col--12 col--sm-6">
            <div className="text--component margin-bottom--sm">
              <h2>{content.title}</h2>
              <ol className="steplist margin-bottom--sm text--md">
                {content.checklists &&
                  content.checklists.map(checklist => (
                    <li key={content.title}>{checklist.text}</li>
                  ))}
              </ol>
              <a
                href="#start"
                className="btn btn--md btn--primary btn--cta"
                data-goal="download_link"
              >
                {content.button}
              </a>
            </div>
          </div>
          <div className="col col--10 col--sm-4">
            <img src={Phone} alt={content.title} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default App

import React from "react"

const allContent = {
  sv: {
    title: "Så fungerar det",
    description:
      "Joint Academy är en digital artrosbehandling för minskad ledsmärta och ökad rörlighet. Via appen i din mobil finns behandlingen tillgänglig var och när du vill.",
  },
  en: {
    title: "How it works",
    description:
      "Specific training improves the stability of the joint and protects it from damage. The app makes it easy to connect with a licensed physical therapist, get reminders, and track your progress. This boosts motivation and improves treatment results",
  },
}

const Banner = ({ lang }) => {
  const language = typeof lang === "undefined" ? "sv" : lang
  const content = allContent[language]
  // console.log(content)
  return (
    <section data-theme="white">
      <div className="container container--md margin-top--lg margin-bottom--md">
        <div className="text--component margin-bottom--md text--center">
          <h2>{content.title}</h2>
          <p>{content.description}</p>
        </div>
      </div>
    </section>
  )
}
export default Banner

import React from "react"
import IconPt from "../assets/icon_pt.svg"
import IconChair from "../assets/icon_chair.svg"
import IconData from "../assets/icon_data.svg"

const allContent = {
  en: {
    ptTitle: "A personal physical therapist",
    ptText:
      "Your physical therapist is always available and supports you throughout the treatments.",
    exerciseTitle: "Personalized exercises",
    exerciseText:
      "Physical activities that automatically adjust to you as you get better. And they only take 5 minutes a day.",
    trackingTitle: "Progress tracking",
    trackingText:
      "It’s easy for both you and your physical therapist to follow up on your progress from week to week.",
  },
  sv: {
    ptTitle: "Personlig fysioterapeut",
    ptText:
      "En personlig fysioterapeut stöttar dig genom hela behandlingen och finns alltid tillgänglig.",
    exerciseTitle: "Anpasssade aktiviteter",
    exerciseText:
      "Ditt personliga program på 5 minuter om dagen minskar smärtan och ökar rörligheten.",
    trackingTitle: "Följ din utveckling",
    trackingText:
      "Du och din fysioterapeut har tillsammans möjlighet att följa dina framsteg från vecka till vecka.",
  },
}
const Features = ({ lang }) => {
  const language = typeof lang === "undefined" ? "sv" : lang
  const content = allContent[language]
  return (
    <section data-theme="ice-blue">
      <div className="container container--lg margin-top--lg margin-bottom--lg">
        <div className="swiper-container swiper--cards cards--float">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="flex flex--column flex--center">
                <img
                  className="margin-bottom--sm image--md"
                  src={IconPt}
                  alt={content.ptTitle}
                />
                <p className="text--title margin-bottom--xs text--bold text--md">
                  {content.ptTitle}
                </p>
                <p>{content.ptText}</p>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="flex flex--column flex--center">
                <img
                  className="margin-bottom--sm image--md"
                  src={IconChair}
                  alt={content.exerciseTitle}
                />
                <p className="text--title margin-bottom--xs text--bold text--md">
                  {content.exerciseTitle}
                </p>
                <p>{content.exerciseText}</p>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="flex flex--column flex--center">
                <img
                  className="margin-bottom--sm image--md"
                  src={IconData}
                  alt={content.trackingTitle}
                />
                <p className="text--title margin-bottom--xs text--bold text--md">
                  {content.trackingTitle}
                </p>
                <p>{content.trackingText}</p>
              </div>
            </div>
          </div>
          <div className="swiper-pagination  margin-top--md"></div>
        </div>
      </div>
    </section>
  )
}
export default Features

import React from "react"
import Video from "./Video"
// import { graphql } from 'gatsby';

const allContent = {
  en: {
    title: "Treat hip and knee pain from home",
    checklists: [
      {
        text: "Personal physical therapists",
      },
      {
        text: "Activities to reduce pain",
      },
      {
        text: "Tools to track progress",
      },
    ],
    subTitle: "Get a text to download the app",
    placeholder: "Your phone number",
    button: "Send",
    videoTitel: "How it works",
    vimeoUrl:
      "https://player.vimeo.com/video/375858455?color=fff&title=0&byline=0&portrait=0",
  },
  sv: {
    title: "Behandla artros via mobilen",
    checklists: [
      {
        text: "Personlig fysioterapeut",
      },
      {
        text: "Anpassade aktiviteter",
      },
      {
        text: "Följ din utveckling",
      },
    ],
    subTitle: "Få ett sms med en länk till appen",
    placeholder: "Ditt mobilnummer",
    button: "Skicka",
    videoTitel: "Så fungerar det",
    vimeoUrl:
      "https://player.vimeo.com/video/378479886?color=fff&title=0&byline=0&portrait=0",
  },
}

const Hero = ({ data, token, videoSrcURL, videoTitel, lang }) => {
  const language = typeof lang === "undefined" ? "sv" : lang
  const content = allContent[language]

  const onSubmit = event => {
    if (typeof window !== "undefined") {
      window.jaSendSms(event)
    }
  }

  return (
    <section id="start" data-theme="ice-blue">
      <div className="container container--lg margin-top--md margin-bottom--lg">
        <div className="grid grid-gap--lg flex--center-y flex--space-between">
          <div className="col col--12 col--md col--lg col--xl-5">
            <h1 className="margin-bottom--xs">{content.title}</h1>
            <ul className="checklist margin-bottom--md text--sm margin-top--sm">
              {content.checklists &&
                content.checklists.map(checklist => (
                  <li key={checklist.text}>
                    {checklist.text}
                    <span />
                  </li>
                ))}
            </ul>
            <p className="text--sm margin-bottom--xxs">{content.subTitle}</p>

            <form
              id="jaSMSForm"
              onSubmit={onSubmit}
              style={{ maxWidth: "400px" }}
              data-token={token}
              className="flex flex--wrap"
            >
              <input
                id="jaSMSFormField"
                className="form-control text--left margin-right--xxs margin-bottom--xs"
                type="tel"
                name="tel"
                autoComplete="off"
                placeholder={content.placeholder}
                required
                style={{ marginTop: "0" }}
              />
              <button
                id="jaSMSFormSubmit"
                type="submit"
                className="btn btn--md btn--primary btn--square"
                style={{ marginTop: "0" }}
              >
                {content.button}
              </button>
            </form>
          </div>
          <div className="col col--12 col--md-6 col--lg-7">
            <div className="case-video">
              <div className="case-video-cover flex flex--center">
                <div className="play flex flex--column flex--center">
                  <span
                    id="play-button-case"
                    className="btn btn--primary flex flex--center aniamtion--ping margin-bottom--sm margin-top--sm"
                    data-goal="played_video"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.999"
                      height="32.001"
                      viewBox="0 0 27.999 32.001"
                    >
                      <path
                        fill="#fff"
                        d="M12.525-14.581l-22-13.006A2.983,2.983,0,0,0-14-25.006V1A3,3,0,0,0-9.475,3.581l22-13A3,3,0,0,0,12.525-14.581Z"
                        transform="translate(14 27.998)"
                      />
                    </svg>
                  </span>
                  <p style={{ color: "#fff" }} className="margin-top--xs">
                    {content.videoTitel}
                  </p>
                </div>
              </div>
              {/* <div id="video" data-vimeo-id=""></div> */}
              <Video
                videoSrcURL={content.vimeoUrl}
                videoTitle={content.videoTitel}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero

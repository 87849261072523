import React from "react"
import Video from "./Video"

const allContent = {
  en: {
    videoTitel: "How it works",
    vimeoUrl:
      "https://player.vimeo.com/video/345932429?color=fff&title=0&byline=0&portrait=0",
  },
  sv: {
    videoTitel: "Så fungerar det",
    vimeoUrl:
      "https://player.vimeo.com/video/332237175?color=fff&title=0&byline=0&portrait=0",
  },
}

const Testimonial = ({ vimeoUrl, vimeoTitle, testimonialTitle, lang }) => {
  const language = typeof lang === "undefined" ? "sv" : lang
  const content = allContent[language]
  return (
    <section data-theme="white" data-section="testimonial">
      <div className="container container--md margin-top--lg margin-bottom--lg">
        <div className="case-video">
          <div className="case-video-cover flex flex--center">
            <div className="play flex flex--column flex--center">
              <span
                id="play-button-case"
                className="btn btn--primary flex flex--center aniamtion--ping margin-bottom--sm margin-top--sm"
                data-goal="played_video"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27.999"
                  height="32.001"
                  viewBox="0 0 27.999 32.001"
                >
                  <path
                    fill="#fff"
                    d="M12.525-14.581l-22-13.006A2.983,2.983,0,0,0-14-25.006V1A3,3,0,0,0-9.475,3.581l22-13A3,3,0,0,0,12.525-14.581Z"
                    transform="translate(14 27.998)"
                  />
                </svg>
              </span>
              <p style={{ color: "#fff" }} className="margin-top--xs">
                {content.videoTitel}
              </p>
            </div>
          </div>
          {/* <div id="video" data-vimeo-id=""></div> */}
          <Video
            videoSrcURL={content.vimeoUrl}
            videoTitle={content.videoTitel}
          />
        </div>
      </div>
    </section>
  )
}
export default Testimonial

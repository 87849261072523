import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Header from "../components/Header"

import Footer from "../components/Footer"

const Layout = ({ children, page, logo, name, Lang }) => {
  return (
    <>
      <SEO />
      <Header logo={logo} name={name} />
      <div className="content">{children}</div>
      <Footer lang={Lang} />
    </>
  )
}

export default Layout

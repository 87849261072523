import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => (
  <Helmet>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
    <title>Joint Academy</title>
    <meta name="description" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
    <link
      rel="icon"
      href="https://assets.jointacademy.com/logos/jointacademy_favicon_pos.png"
    />
    <meta name="theme-color" content="#1274e3" />
    <meta name="robots" content="noindex, nofollow" />
    <meta name="format-detection" content="telephone=no" />
    <meta property="og:title" content="" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="" />
  </Helmet>
)

export default SEO

import React from "react"

const allContent = {
  en: {
    policy: "Privacy policy",
    policyLink: "https://www.jointacademy.com/us/en/policy/",

    terms: "Terms of use",
    termsLink: "https://www.jointacademy.com/us/en/terms/",

    hipaa: "HIPAA",
    hipaaLink: "https://www.jointacademy.com/us/en/hipaa/",
  },
  sv: {
    policy: "Personuppgiftspolicy",
    policyLink: "https://www.jointacademy.com/se/sv/policy/",

    terms: "Allmänna Villkor",
    termsLink: "https://www.jointacademy.com/se/sv/villkor/",

    hipaa: "CE-märkning",
    hipaaLink: "https://www.jointacademy.com/se/sv/ce/",
  },
}

const Footer = ({ lang }) => {
  const language = typeof lang === "undefined" ? "sv" : lang

  const content = allContent[language]

  return (
    <footer id="footer" className="text--xs" data-theme="dark">
      <div className="container container--lg margin-top--md margin-bottom--md">
        <div className="flex--md flex--space-between text--center grid grid-gap--sm">
          <div className="col col--12 col--sm-6 text--left-sm">
            <a href="http://jointacademy.com/" target="_blank" rel="noreferrer">
              <img
                src="https://assets.jointacademy.com/logos/jointacademy_logo_neg.svg"
                className="logo--sm"
                alt="dsadsa"
              />
            </a>
          </div>
          <div className="col col--12 col--sm-6 flex--sm flex--end flex--center-y">
            <a
              href={content.policyLink}
              target="_blank"
              rel="noreferrer"
              className="main-header__nav-link opacity--4"
            >
              {content.policy}
            </a>
            <a
              href={content.termsLink}
              target="_blank"
              rel="noreferrer"
              className="main-header__nav-link opacity--4 margin-left--sm margin-right--sm"
            >
              {content.terms}
            </a>
            <a
              href={content.hipaaLink}
              target="_blank"
              rel="noreferrer"
              className="main-header__nav-link opacity--4"
            >
              {content.hipaa}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer

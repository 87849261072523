import React from "react"
import Stars from "../assets/Stars.svg"
import Users from "../assets/users.svg"

const allContent = {
  en: {
    quotes: [
      {
        name: "Robert",
        text:
          "I don’t need any painkillers or surgery and I can still play tennis.",
      },
      {
        name: "Lotta",
        text:
          "It took me five minutes a day to make a difference and feel good.",
      },
      {
        name: "Åke",
        text: "My pain disappeared after a few weeks with Joint Academy.",
      },
    ],
    recommend: "would recommend",
    rating: "Google rating",
    treated: "patients treated",
  },
  sv: {
    quotes: [
      {
        name: "Robert",
        text:
          "Jag tar inga värktabletter, behöver inte operera mig och kan fortsätta spela tennis.",
      },
      {
        name: "Lotta",
        text:
          "Övningar som tar två minuter lät som ett skämt men efter ett par veckor kände jag en förändring.",
      },
      {
        name: "Åke",
        text:
          "Smärta har jag aldrig. Den tycker jag försvann efter ett par veckor med Joint Academy.",
      },
    ],
    recommend: "rekommenderar",
    rating: "betyg på Google",
    treated: "patienter behandlade",
  },
}

const SocialProof = ({
  recommend,
  recommendData,
  grade,
  gradeData,
  patient,
  patientData,
  lang,
}) => {
  const language = typeof lang === "undefined" ? "sv" : lang
  const content = allContent[language]

  return (
    <section id="socialproof" data-theme="float" data-name="socialproof">
      <div className="container container--lg margin-top--sm margin-bottom--sm">
        <div className="stats_bar">
          <div className="grid flex flex--center-y">
            <div className="col col--12 col--lg-7">
              <div className="flex flex--space-between flex--center-y">
                <div className="text--center" style={{ flexShrink: "0" }}>
                  <p
                    className="text--xl text--title text--700"
                    style={{ lineHeight: "1em" }}
                  >
                    {recommendData}
                  </p>
                  <p className="text--sm text--title opacity--8 margin-bottom--xxs">
                    {content.recommend}
                  </p>
                  <img src={Stars} height="14" alt="rekommend" />
                </div>
                <div
                  style={{ height: "56px" }}
                  className="divider divider--vertical opacity--4"
                ></div>
                <div className="text--center" style={{ flexShrink: "0" }}>
                  <p
                    className="text--xl text--title text--700"
                    style={{ lineHeight: "1em" }}
                  >
                    {gradeData}
                  </p>
                  <p className="text--sm text--title opacity--8 margin-bottom--xxs">
                    {content.rating}
                  </p>
                  <img src={Stars} height="14" alt="rekommend" />
                </div>
                <div
                  style={{ height: "56px" }}
                  className="divider divider--vertical opacity--4"
                ></div>
                <div className="text--center" style={{ flexShrink: "0" }}>
                  <p
                    className="text--xl text--title text--700"
                    style={{ lineHeight: "1em" }}
                  >
                    {patientData}
                  </p>
                  <p className="text--sm text--title opacity--8 margin-bottom--xxs">
                    {content.treated}
                  </p>
                  <img src={Users} height="14" alt="rekommend" />
                </div>
                <div
                  style={{ height: "56px" }}
                  className="divider divider--vertical divider--75 opacity--4 display--lg"
                ></div>
              </div>
            </div>
            <div className="col col--lg-5 display--lg">
              <div style={{ overflow: "hidden" }}>
                <div className="swiper-container swiper-container--slide">
                  <div className="swiper-wrapper">
                    {content.quotes &&
                      content.quotes.map(quote => (
                        <div
                          key={quote.name}
                          className="swiper-slide"
                          style={{ width: "100%!important" }}
                        >
                          <p>
                            “{quote.text}”
                            <span className="opacity--6 margin-left--xxs">
                              - {quote.name}
                            </span>
                            <br />
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SocialProof
